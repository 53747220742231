var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    [
      _c(
        "a-button",
        {
          attrs: { type: "primary", icon: "desktop" },
          on: { click: _vm.splitPane },
        },
        [_vm._v("点我分屏")]
      ),
      _c("split-panel-modal", { ref: "splitPanelModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }